






































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { Apiary, Hive, User } from '@/types';

@Component({
  components: {
    HiveHoneycombs: () => import('@/components/HiveHoneycombs.vue'),
    HiveAdoptions: () => import('@/components/HiveAdoptions.vue'),
    HiveDetailsDialog: () => import('@/components/HiveDetailsDialog.vue'),
  },
})
export default class HivesAdminDialog extends Vue {
  dialog = false
  loading = false

  tab: any = null

  headers = [
    { text: 'Arnia', value: 'hiveName', sortable: false },
    {
      text: 'Favi',
      value: 'honeycombs',
      sortable: false,
      align: 'start',
    },
    { text: 'Adozioni', value: 'adoptions', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  get value(): boolean {
    return this.$store.getters.hivesAdminDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  // Restituisce l'elenco degli apiari escludendo quelli disattivati
  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries
      .filter((a: Apiary) => a.enabled !== false);
  }

  get apiary(): Apiary | null {
    return this.$store.getters.apiary;
  }

  get apiaryHives(): Hive[] {
    return this.$store.getters.apiaryHives;
  }

  get filteredHives(): Hive[] {
    return this.apiaryHives
      // eslint-disable-next-line arrow-body-style
      ? this.apiaryHives.filter((h) => {
        // Filtro sugli archiviati
        // if (!this.filters.archived && p.archived) return false;

        // Filtro sulla fase
        // eslint-disable-next-line no-prototype-builtins
        // if (this.filters.phase && !p.phases.hasOwnProperty(this.filters.phase)) return false;

        // TODO: Campo di ricerca
        // if (this.filters.search && this.filters.search.trim()
        //   && !p.name[this.userLanguage]
        //     .toLowerCase().includes(this.filters.search.toLowerCase())) return false;

        return true;
      })
      : [];
  }

  get filteredApiaries(): Apiary[] {
    return this.apiaries
      // eslint-disable-next-line arrow-body-style
      ? this.apiaries.filter((a) => {
        return true;
      })
      : [];
  }

  @Watch('value')
  async onValueChanged(val: boolean) {
    const debugPrefix = 'HivesAdminDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'HivesAdminDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'hivesAdmin');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onDelete() {
    this.dialog = false;
  }

  onEdit() {
    this.dialog = false;
  }

  async onDetails(item: Hive) {
    if (this.loading || item?.id === null) return;

    this.loading = true;

    await this.$store.dispatch('selectHive', item.id);

    setTimeout(() => {
      this.$store.dispatch('openDialog', 'hiveDetails');
      this.loading = false;
    }, 1000);
  }

  async onTabChange(val: number) {
    const debugPrefix = 'HivesAdminDialog: onTabChange()';
    console.log(`${debugPrefix} val = ${val}`); // eslint-disable-line no-console

    const apiaryId = val in this.apiaries ? this.apiaries[val].id : null;
    console.log(`${debugPrefix} apiaryId = ${apiaryId}`); // eslint-disable-line no-console

    if (apiaryId !== null) {
      this.loading = true;
      await this.$store.dispatch('selectApiary', apiaryId);
      this.loading = false;
    }
  }
}
