var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-hives-admin-dialog"},[_c('v-dialog',{ref:"dialog",attrs:{"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"dialog-card"},[_c('h1',{staticClass:"dialog-name"},[_vm._v("Gestione arnie")]),_c('div',{staticClass:"dialog-descr body-1 pb-4 mt-4"},[_c('v-tabs',{on:{"change":_vm.onTabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.apiaries),function(apiary){return _c('v-tab',{key:("apiary-" + (apiary.id) + "-tab")},[_vm._v(_vm._s(apiary.name))])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.apiaries),function(apiary){return _c('v-tab-item',{key:("apiary-" + (apiary.id) + "-tab-item")},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredHives,"item-key":"id","items-per-page":5,"footer-props":{
                  itemsPerPageOptions: [5, 10, 20, 30, -1],
                }},scopedSlots:_vm._u([{key:"item.hiveName",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.name))])]}},{key:"item.honeycombs",fn:function(ref){
                var item = ref.item;
return [_c('HiveHoneycombs',{attrs:{"hive":item}})]}},{key:"item.adoptions",fn:function(ref){
                var item = ref.item;
return [_c('HiveAdoptions',{attrs:{"hive":item}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ml-1",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"dark":!_vm.loading,"fab":"","x-small":"","depressed":"","color":"#D19555"},on:{"click":function($event){return _vm.onDetails(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","disabled":"","dark":false},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)]),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('div',{staticClass:"d-none d-sm-flex flex-grow-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","depressed":"","outlined":"","color":"#D19555","dark":""},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")])],1),_c('div',{staticClass:"d-flex d-sm-none flex-grow-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","outlined":"","color":"#D19555","dark":""},on:{"click":_vm.onCancel}},[_vm._v(" Chiudi ")])],1)])],1)],1),_c('HiveDetailsDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }